import { Box } from '@chakra-ui/react';
import getAppConfig from '../../utils/getAppConfig';
import { AdSlotFragment } from '../../generated/types';

interface AdSlotProps {
  data: AdSlotFragment;
}

export const AdSlotBase: React.FC<AdSlotProps> = ({ data }) => {
  const appConfig = getAppConfig();

  const slot = data;

  if (!slot?.slotId || !appConfig.config?.adsense?.id) {
    return null;
  }

  const props: Record<string, any> = {};

  if (slot?.responsive) {
    props['data-full-width-responsive'] = 'true';
  }

  if (slot?.layout) {
    props['data-ad-layout'] = slot?.layout;
  }

  return (
    <Box
      width="100%"
      minHeight={slot?.maxHeight ? `${slot?.maxHeight}px` : 'auto'}
      my={{
        base: 4,
        md: 6,
      }}
    >
      <ins
        className="adsbygoogle"
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        }}
        data-ad-client={`ca-pub-${appConfig.config.adsense.id}`}
        data-ad-slot={slot?.slotId}
        data-ad-format={slot?.format || 'auto'}
        {...props}
      ></ins>
      <script
        dangerouslySetInnerHTML={{
          __html: `(adsbygoogle = window.adsbygoogle || []).push({});`,
        }}
      />
    </Box>
  );
};

export default AdSlotBase;
