import { ParagraphAdSlotFragment } from '../../../generated/types';
import AdSlotBase from '../../Common/AdSlotBase';

interface AdSlotProps {
  data: ParagraphAdSlotFragment;
}

export const AdSlot: React.FC<AdSlotProps> = ({ data }) => {
  return <AdSlotBase data={data.slot} />;
};

export default AdSlot;
